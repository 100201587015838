import React from 'react';

import './Navbar.css';

const navMenu = (
        <ul>
            <li><a href="default.asp">Home</a></li>
            <li><a href="news.asp">News</a></li>
            <li><a href="contact.asp">Contact</a></li>
            <li><a href="about.asp">About</a></li>
        </ul> 
)

function Navbar() {
    return (
        <>
            <div className="navBar--container">
                  
            </div>
        </>

    )
}

export default Navbar
