import React from 'react';
import './Main.css';
import Buttons from './Buttons'

const welcomeMessage = <h3>Get Fit Now.</h3>
const mainTagline = <p> One on One customized personal training.</p>

function Main() {
    return (
        <>
            <div className="main--content">
                {welcomeMessage}
                {mainTagline}
                <Buttons link="https://docs.google.com/forms/d/1iKYRF5NTCaL8TsGsJqbGFvNdygsFgBAgtFoRxxGFM-w" target="_blank"name="Request a Free Session" style="big-red" />
                <Buttons link="mailto:lexie@lexiepreston.com" target="_self" name="Contact Lexie" style="big-white" />

                
            </div>  

            <div className="sub--contentWrap">

            <div className="sub--content">
                <p className="minicon"><i class="fas fa-fire-alt"></i></p>
                <p className="subtext">Burn fat and build muscle with workouts tailored to your personal goals.</p>
                <p className="minicon"><i class="fas fa-heartbeat"></i></p>
                <p className="subtext">The American Heart Association recommends at least 150 minutes of physical activity per week.</p>
                <p className="minicon"><i class="far fa-calendar-alt"></i></p>
                <p className="subtext">Having a set schedule for your fitness routine is the best way to kickstart a new healthy lifestyle and stay committed to your goals.</p>
                <p className="minicon"><i class="far fa-grin"></i></p>
                <p className="subtext">Feel good about yourself! Exercise not only boosts your physical health but your mental health as well. Schedule a free session and consultation with me today!</p>
            </div>

            </div>

            
        </>
    )
}

export default Main
