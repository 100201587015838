import './App.css';
//import Main from './components/Main'
//import Navbar from './components/Navbar'
import Video from './components/Video'
import Mainwrap from './components/Mainwrap'



function App() {
  return (
    <>
      <Video />
      <Mainwrap />
    </>
  );
}

export default App;
