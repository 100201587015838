import React from 'react';
import './Video.css';

//src="/videos/video-3.mp4"

const mainVideo = <video src="https://l3xi3bux.s3.us-east-2.amazonaws.com/video-3.mp4" autoPlay loop muted />;

function Video() {
    return (
        <div className="videoContainer">
          {mainVideo}
        </div>
    )
}

export default Video
