import React from 'react'
import './Buttons.css'

function Buttons(props) {
    return (
        <a href={props.link} target={props.target} className={props.style}>{props.name}</a>
    )
}

export default Buttons
