import React from 'react'
import Navbar from './Navbar'
import Main from './Main'
import Footer from './Footer.js'
import './Mainwrap.css'

function Mainwrap() {
    return (
        <div className="main--container">
            <Navbar />
            <Main />
            <Footer />
        </div>
    )
}

export default Mainwrap
